import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { BASE_URI, DEALERMAKEID } from "../shared/Constants";
import { FinanceContext } from "../shared/contexts/FinanceContext";

export default function OurVehiclesController() {
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [total, setTotal] = useState(false);

  let { setGlobalDealerVehicles } = useContext(FinanceContext);

  useEffect(() => {
    let cancel;
    setLoading(true);
    setVehicles([]);

    const params = {
      pageNumber: 1,
      dealerId: 286,
      pageSize: 1000,
    };

    axios({
      method: "GET",
      url: `${BASE_URI}/dealermodels`,
      params,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((response) => {
        let renaultVehicles = response.data?.list.filter(
          (x) => x.makeId == DEALERMAKEID
        );

        setVehicles(renaultVehicles);
        setGlobalDealerVehicles(renaultVehicles);
        setTotal(renaultVehicles.length > 0);
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        setLoading(false);
      });
    return () => cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DEALERMAKEID]);

  return { loading, vehicles, total };
}
