import React from "react";
import { Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import NavbarComponent from "./NavbarComponent";
import HomeComponent from "./homePage/HomeComponent";
import FooterComponent from "./homePage/FooterComponent";
import ApplyFinance from "./applyFinancePage/ApplyFinance";
import PreApplication from "./applyFinancePage/PreApplication";
import PostMfc from "./applyFinancePage/PostMfc";
import PQSuccess from "./applyFinancePage/PQSuccess";
import AfterSalesComponent from "./afterSalesPages/AfterSalesComponent";
import SpecialDealsComponent from "./specialDeals/SpecialDealsComponent";
import SpecialDealsComponentDetailed from "./specialDeals/SpecialDealsComponentDetailed";
import CarProfileContainer from "./carProfile/CarProfileContainer";
import OurDealers from "./ourDealersPage/OurDealers";
import OurVehicles from "./ourVehiclesPage/OurVehicles";
import ContactUs from "./contactUsPage/ContactUs";
import Terms from "./legalPage/Terms";
import Privacy from "./legalPage/Privacy";
import Cookies from "./legalPage/Cookies";
import Popi from "./legalPage/Popia";
import ScrollUpButton from "react-scroll-up-button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: "45ch",
    },
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: {},
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
}));

export default function DashboardContainer() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <main>
        <NavbarComponent />
        <Route exact path="/" render={(props) => <HomeComponent />} />
        <Route exact path="/apply-for-finance" component={ApplyFinance} />
        <Route
          exact
          path="/apply-for-finance/done/:leadId"
          component={PreApplication}
        />
        <Route
          exact
          path="/pqComplete/leadId/:leadId/a/:a/i/:i"
          component={PostMfc}
        />
        <Route exact path="/pqSuccess/:leadId" component={PQSuccess} />
        <Route
          path={[
            "/maintenance",
            "/warranty",
            "/tomtom-live",
            "/service-plans",
            "/bodyshops",
            "/roadside-assistance",
          ]}
          render={(props) => <AfterSalesComponent {...props} />}
        />
        <Route
          exact
          path="/special-deals"
          render={(props) => <SpecialDealsComponent />}
        />
        <Route
          exact
          path="/special-deals/:carId/:modelId/:dealId?"
          render={(props) => <SpecialDealsComponentDetailed />}
        />
        {/*<Route exact path="/new/:model/" component={CarProfileContainer}/>*/}
        <Route
          exact
          path="/models/:carId/:dealId?"
          component={CarProfileContainer}
        />
        <Route path="/our-dealers" component={OurDealers} />
        <Route path="/our-vehicles" component={OurVehicles} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/requestergws" component={Popi} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/cookies" component={Cookies} />
        <FooterComponent />
        <ScrollUpButton />
      </main>
    </React.Fragment>
  );
}
