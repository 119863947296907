import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { Formik, Form, Field } from "formik";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: 'black',
        padding: theme.spacing(1, 15, 1),
        display: 'flex',
        alignItems: 'center',
        position:'fixed',
        zIndex:'99',
        '@media (max-width: 426px)': {
            padding: theme.spacing(1, 1, 1 ,1),
            flexDirection:'column',
        },
        '@media (min-width: 426px) and (max-width: 1024px)': {
            padding: theme.spacing(1, 1, 1 ,1),
            flexDirection:'column',
        },
    },
    btn: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(1, 5, 1),
        background:'#efdf00',
        color: 'black',
        border: 'none',
        borderRadius: '2px',
        margin: '0 15px',
        fontSize: '12px',
        whiteSpace:'nowrap',
        '@media (max-width: 426px)': {
            margin: theme.spacing(1, 1, 1 ,1),
            textAlign:'center'
        },
        '@media (min-width: 426px) and (max-width: 1024px)': {
            margin: theme.spacing(1, 5, 1 ,2),
            textAlign:'center'
        },
        '&:hover' : {
            backgroundColor: '#F8EB4C'
        }
    },
    text: {
        color: 'white',
        margin: theme.spacing(1, 10, 1 ,1),
        width: '80%',
        fontSize:'14px',
        '@media (max-width: 426px)': {
            margin: theme.spacing(1, 1, 1 ,1),
            textAlign:'center'
        },
        '@media (min-width: 426px) and (max-width: 1024px)': {
            margin: theme.spacing(1, 5, 1 ,2),
            textAlign:'center'
        },
    },
    buttonHolder: {
        display: 'flex',
        padding: theme.spacing(0, 0, 0),
        height: '100%',
        alignItems: 'center',
        '@media (max-width: 426px)': {
            flexDirection:'column',
        },
    },
    paper:{
        backgroundColor: "rgba(0, 0, 0, 0.95)",
        color: "#fff",
        padding: "0 15px 15px 15px;",
        transition: "height 0.5s ease",
        '@media (max-width: 526px)': {
            margin: '0',
        },
    },
    backdrop:{
        backgroundColor: "transparent"
    },
    grid:{
        paddingTop: "15px !important"
    },
    link: {
        fontWeight: "500"
    },
    linkButton:{
        marginTop: "10px",
        cursor: "pointer",
        fontSize: "13px",
        fontWeight: "bold",
        textDecoration: "underline",
        '@media (max-width: 526px)': {
            fontSize: '10px'
        },
    },
    cookieDescription:{
        color: "#fff",
        marginLeft: "25px",
        marginTop: "5px",
        fontWeight: "100",
    },
    content:{
        color: "#fff",
        fontWeight: "100",
        fontSize: "14px",
        '@media (max-width: 526px)': {
            fontSize: '9px'
        },
    },
    heading:{
        paddingBottom: "0",
    },
    title:{
        fontSize: "25px",
        marginBottom: "15px",
        marginTop: "0",
        borderBottom: "1px solid #bbb",
        fontWeight: "100",
        paddingBottom: "15px",
        '@media (max-width: 526px)': {
            fontSize: '15px'
        },
    },
    bold: {
        fontWeight: "400",
        textTransform: "uppercase",
    },
}));

const writeCookie = (name, value, days) => {
    var date, expires;
    if (days) {
        date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();

    } else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + ";";
}

const getCookieValue = (a) => {

    var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';

};


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

export default function CookiePopUp() {
    const [hasValue, setHasValue] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [essentialCookies, setEssentialCookies] = useState(true);
    const [analyticsCookies, setAnalyticsCookies] = useState(false);
    const [advertisingCookies, setAdvertisingCookies] = useState(false);
    const [thirdPartyCookies, setThirdPartyCookies] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const classes = useStyles();

    const openDialog = () => {
        setOpen(true);
    };
    
    const acceptAll = () => {
        setCookie(false);
        disableGoogleAnalytics(false);
        enableFbPixel();
        allowAddThisCookie();
    };
    
    const rejectAll = () =>{
        setCookie(false);
        disableGoogleAnalytics(true);
        setOpen(false);
        setOpen(false);
    };
    
    const saveAndClose = () => { 
        if(advertisingCookies){
            enableFbPixel();
        }
    
        if(analyticsCookies) {
            disableGoogleAnalytics(false);
        }
        else { 
            disableGoogleAnalytics(true);
        }
    
        if(thirdPartyCookies){
            allowAddThisCookie();
        }
        setCookie(false);
        setOpen(false);
    };

    const allowAddThisCookie = () =>{
        writeCookie('addThis', true, 365)
        addThisCookie();
    };
    
    const acceptEssential = () =>{
        setCookie(true);
        setOpen(false);
    };
    
    const setCookie = (val) => {
        setHasValue(true);
    
        if (val) {
            writeCookie('noCookie', true, 1);
        }
        else {
            writeCookie('noCookie', false, 365);
        }
    };

    const disableGoogleAnalytics = (state) => {
        window["ga-disable-UA-161653596-1"] = state;
    };
      
    const enableFbPixel = () => {
        /*global fbq*/
        /*eslint no-undef: "error"*/
        fbq("init", "2824902194411905");
        fbq("track", "PageView");
    };

    const addThisCookie = () =>{
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-6028ed60b640aa23';
        head.appendChild(script);
    };

    useEffect(() => {

        if (getCookieValue("noCookie").length > 0) {
            setHasValue(true);
        }
        else{
            setHasValue(false);
            openDialog();
        }
  
        if(getCookieValue('addThis').length > 0 && getCookieValue('addThis') == 'true'){
            addThisCookie();
        }
        if (getCookieValue('noCookie').length > 0) {
            setHasValue(true);
        }

    }, []);

    return (
        <div>
            {!hasValue && (
                <div>
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={acceptEssential}
                  aria-describedby="alert-dialog-slide-description"
                  BackdropProps={{ className: classes.backdrop }}
                  PaperProps={{ className: classes.paper }}
                  className={classes.dialogContainer}
                >
                  <DialogTitle className={classes.heading}>
                    <h2 className={classes.title}>Welcome to <span className={classes.bold}>Renault Virtual</span></h2>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      className={classes.content}
                      id="alert-dialog-slide-description"
                    >
                      We value your privacy and commit ourselves to processing your
                      personal information fairly and lawfully. This website uses
                      cookies, which help to run the website in various ways,
                      including providing essential services, advertising and
                      analytics. By accepting or dismissing this notice, or continuing
                      to use this website, you consent to us using essential cookies.
                      You can find out more information about how we manage{" "}
                      <Link
                        component={RouterLink}
                        to="/cookies"
                        className={classes.link}
                      >
                        cookies,
                      </Link>{" "}
                      and personal information in{" "}
                      <Link
                        component={RouterLink}
                        to="/privacy"
                        className={classes.link}
                      >
                        privacy.
                      </Link>
                    </DialogContentText>
                    <div
                      className={classes.linkButton}
                      onClick={() => {
                        setShowDetails(!showDetails);
                      }}
                    >
                      {showDetails ? "Hide details" : "Show details"}
                    </div>
      
                    {showDetails && (
                      <Formik
                        initialValues={{
                          essentialCookies: essentialCookies,
                          analyticsCookies: analyticsCookies,
                          advertisingCookies: advertisingCookies,
                          thirdPartyCookies: thirdPartyCookies,
                        }}
                      >
                        <Form>
                          <DialogContentText>
                            <Grid
                              item
                              className={classes.grid}
                              xs={12}
                              md={12}
                              lg={12}
                              style={{ paddingTop: "50px" }}
                            >
                              <FormControlLabel
                                control={
                                  <Field
                                    type="checkbox"
                                    name="essentialCookies"
                                    disabled
                                    onClick={() => {
                                      setEssentialCookies(!essentialCookies);
                                    }}
                                  />
                                }
                              />
                              <span
                                className={classes.content}
                                onClick={() => {
                                  setEssentialCookies(!essentialCookies);
                                }}
                              >
                                Essential Cookies
                              </span>
                              <div className={classes.cookieDescription}>
                                These are cookies that allow our website to perform
                                its essential functions. Without these cookies, some
                                parts of our websites would stop working.
                              </div>
                            </Grid>
      
                            <Grid
                              item
                              className={classes.grid}
                              xs={12}
                              md={12}
                              lg={12}
                              style={{ paddingTop: "50px" }}
                            >
                              <FormControlLabel
                                control={
                                  <Field
                                    type="checkbox"
                                    name="analyticsCookies"
                                    onClick={() => {
                                      setAnalyticsCookies(!analyticsCookies);
                                    }}
                                  />
                                }
                              />
                              <span
                                className={classes.content}
                                onClick={() => {
                                  setAnalyticsCookies(!analyticsCookies);
                                }}
                              >
                                Analytic Cookies
                              </span>
                              <div className={classes.cookieDescription}>
                                These are cookies that monitor how our website is
                                performing, and how you interact with it. We use them
                                to know how best to improve our website or services.
                              </div>
                            </Grid>
      
                            <Grid
                              item
                              className={classes.grid}
                              xs={12}
                              md={12}
                              lg={12}
                              style={{ paddingTop: "50px" }}
                            >
                              <FormControlLabel
                                control={
                                  <Field
                                    type="checkbox"
                                    name="advertisingCookies"
                                    onClick={() => {
                                      setAdvertisingCookies(!advertisingCookies);
                                    }}
                                  />
                                }
                              />
                              <span
                                className={classes.content}
                                onClick={() => {
                                  setAdvertisingCookies(!advertisingCookies);
                                }}
                              >
                                Advertising Cookies
                              </span>
                              <div className={classes.cookieDescription}>
                                These are cookies that matches your interests or web
                                searches with applicable advertising campaigns on our
                                website. We use them to provide you with advertising
                                that we think you might find useful.
                              </div>
                            </Grid>
      
                            <Grid
                              item
                              className={classes.grid}
                              xs={12}
                              md={12}
                              lg={12}
                              style={{ paddingTop: "50px" }}
                            >
                              <FormControlLabel
                                control={
                                  <Field
                                    type="checkbox"
                                    name="thirdPartyCookies"
                                    onClick={() => {
                                      setThirdPartyCookies(!thirdPartyCookies);
                                    }}
                                  />
                                }
                              />
                              <span
                                className={classes.content}
                                onClick={() => {
                                  setThirdPartyCookies(!thirdPartyCookies);
                                }}
                              >
                                Third Party Cookies
                              </span>
                              <div className={classes.cookieDescription}>
                                These are cookies that some of our business partners
                                use on our website. We have no access to or control
                                over them. Information collected by any of these
                                cookies or widgets is governed by the privacy policy
                                of the company that created it, and not by us.
                              </div>
                            </Grid>
                          </DialogContentText>
                        </Form>
                      </Formik>
                    )}
                  </DialogContent>
                  {!showDetails && (
                    <DialogActions>
                      <Button className={classes.btn} onClick={acceptAll}>
                        Allow all cookies
                      </Button>
                      <Button className={classes.btn} onClick={rejectAll}>
                        Reject all cookes
                      </Button>
                    </DialogActions>
                  )}
                  {showDetails && (
                    <DialogActions>
                      <Button
                        className={classes.btn}
                        type="submit"
                        onClick={saveAndClose}
                      >
                        Save and Close
                      </Button>
                      <Button
                        className={classes.btn}
                        onClick={() => {
                          setShowDetails(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  )}
                </Dialog>
              </div>
            )}
        </div>
    )
}