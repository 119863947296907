import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FacebookIcon from "@material-ui/icons/Facebook";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Box from "@material-ui/core/Box";
import DriveEtaOutlinedIcon from "@material-ui/icons/DriveEtaOutlined";
import HeadsetIcon from "@material-ui/icons/Headset";
import TextsmsIcon from "@material-ui/icons/Textsms";
import PlaceIcon from "@material-ui/icons/Place";
import { NavLink as RouterLink } from "react-router-dom";
import { useMediaQuery as mq } from "react-responsive";
import ContactDetailsController from "../contactUsPage/ContactDetailsController";

function Copyright() {
  return (
    <React.Fragment className="footerLink">
      {"© "}
      Renault Virtual {new Date().getFullYear()}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: "flex",
  },
  icon: {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(1),
    color: "#000000",
  },
  list: {
    margin: 0,
    listStyle: "none",
    padding: 0,
  },
  listbottom: {
    margin: 0,
    listStyle: "none",
    padding: 0,
    display: "flex",
  },
  background: {
    backgroundColor: "#000000",
    display: "flex",
    margin: 0,
    listStyle: "none",
    padding: "10px 5%",
    maxWidth: "unset",
  },
  listbottom: {
    display: "flex",
    margin: 0,
    listStyle: "none",
    padding: "10px 20px",
  },
  bottomlistItem: {
    paddingRight: theme.spacing(2),
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
  footerIconSection: {
    backgroundColor: "#000000",
  },
  footerIconSectionLink: {
    color: "#ffffff",
  },
  footerIconSectionIcon: {
    color: "#EFDF00",
  },
  footerLink: {
    color: "#7c7c7c!important",
  },
  stickyBarInner: {
    backgroundColor: "#F8F8F8",
    textAlign: "center",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%",
  },
  stickyBar: {
    display: "block",
    padding: "20px",
    height: "60px",
    width: "100%",
  },
  stickyBarImage: {
    width: "100%",
    height: "100%",
  },
}));

const LANGUAGES = [
  {
    code: "en-US",
    name: "English",
  },
  {
    code: "fr-FR",
    name: "Français",
  },
];

export default function FooterComponent() {
  const classes = useStyles();
  const isMobile = mq({ query: "(max-width: 717px)" });
  const { dealerContactDetails, dealerSocialMedia } =
    ContactDetailsController();

  return (
    <Fragment>
      <Container className={classes.container}>
        <div className="addthis_inline_share_toolbox"></div>
      </Container>
      <Box className={classes.footerIconSection}>
        <Container className={classes.container}>
          <Grid container spacing={5} className={classes.footerIconSection}>
            <Grid item xs={12} sm={6} md={3} align="center">
              <Box mb={2}>
                <HeadsetIcon
                  className={classes.footerIconSectionIcon}
                  style={{ fontSize: 50 }}
                />
              </Box>
              <Typography className={classes.footerIconSectionLink}>
                Call us now
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} align="center">
              <Box mb={2}>
                <TextsmsIcon
                  className={classes.footerIconSectionIcon}
                  style={{ fontSize: 50 }}
                />
              </Box>
              <Typography className={classes.footerIconSectionLink}>
                Were Online
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} align="center">
              <Box mb={2}>
                <DriveEtaOutlinedIcon
                  className={classes.footerIconSectionIcon}
                  style={{ fontSize: 50 }}
                />
              </Box>
              <Typography className={classes.footerIconSectionLink}>
                Test drive
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} align="center">
              <Box mb={2}>
                <PlaceIcon
                  className={classes.footerIconSectionIcon}
                  style={{ fontSize: 50 }}
                />
              </Box>
              <Typography className={classes.footerIconSectionLink}>
                Nationwide delivery
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Typography component="footer" className={classes.root}>
        <Container className={classes.container}>
          <Box></Box>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" marked="left" gutterBottom>
                customer service
              </Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <Link className={classes.footerLink} href="/contact-us">
                    contact us
                  </Link>
                </li>
                <li className={classes.listItem}>
                  <Link
                    className={classes.footerLink}
                    href={`mailto:${dealerContactDetails.email}`}
                  >
                    {dealerContactDetails.email}
                  </Link>
                </li>
                <li className={classes.listItem}>
                  <Link
                    className={classes.footerLink}
                    href={`tel:${dealerContactDetails.newCarsPhoneNumber?.replace(
                      / /g,
                      ""
                    )}`}
                  >
                    {dealerContactDetails.newCarsPhoneNumber}
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" marked="left" gutterBottom>
                Renault services
              </Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <Link className={classes.footerLink} href="/special-deals">
                    financed offers
                  </Link>
                </li>
                <li className={classes.listItem}>
                  <Link className={classes.footerLink} href="/warranty">
                    5 year warranty
                  </Link>
                </li>
                <li className={classes.listItem}>
                  <Link
                    className={classes.footerLink}
                    href="/apply-for-finance"
                  >
                    apply for finance
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" marked="left" gutterBottom>
                after sales
              </Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <Link className={classes.footerLink} href="/maintenance">
                    maintain your Renault
                  </Link>
                </li>
                <li className={classes.listItem}>
                  <Link className={classes.footerLink} href="/service-plans">
                    service plans
                  </Link>
                </li>
                <li className={classes.listItem}>
                  <Link
                    className={classes.footerLink}
                    href="/roadside-assistance"
                  >
                    24 hour road side assistance
                  </Link>
                </li>
              </ul>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" marked="left" gutterBottom>
                stay connected
              </Typography>
              <Grid
                container
                direction="column"
                className={classes.iconsWrapper}
                spacing={2}
              >
                <Grid item className={classes.icons}>
                  <IconButton className={classes.icon}>
                    <Link
                      href={
                        !!dealerSocialMedia.facebook
                          ? dealerSocialMedia.facebook
                          : `https://www.facebook.com/renaultvirtual`
                      }
                      className={classes.footerLink}
                      target="_blank"
                    >
                      <FacebookIcon />
                    </Link>
                  </IconButton>
                  <IconButton className={classes.icon}>
                    <Link
                      href={
                        !!dealerSocialMedia.linkedIn
                          ? dealerSocialMedia.linkedIn
                          : `https://www.linkedin.com/company/renault-south-africa/`
                      }
                      className={classes.footerLink}
                      target="_blank"
                    >
                      <LinkedInIcon />
                    </Link>
                  </IconButton>
                  <IconButton className={classes.icon}>
                    <Link
                      href={
                        !!dealerSocialMedia.instagram
                          ? dealerSocialMedia.instagram
                          : `https://www.instagram.com/renault_sa/`
                      }
                      className={classes.footerLink}
                      target="_blank"
                    >
                      <InstagramIcon />
                    </Link>
                  </IconButton>
                  <IconButton className={classes.icon}>
                    <Link
                      href={
                        !!dealerSocialMedia.twitter
                          ? dealerSocialMedia.twitter
                          : `https://twitter.com/renault_sa`
                      }
                      className={classes.footerLink}
                      target="_blank"
                    >
                      <TwitterIcon />
                    </Link>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid xs={12} sm={12} md={12}>
              <ul className={classes.listbottom}>
                <li className={classes.bottomlistItem}>
                  <Link
                    component={RouterLink}
                    to="/privacy"
                    className={classes.footerLink}
                  >
                    privacy policy
                  </Link>
                </li>
                <li className={classes.bottomlistItem}>
                  <Link
                    component={RouterLink}
                    to="/terms"
                    className={classes.footerLink}
                  >
                    legal information
                  </Link>
                </li>
                <li className={classes.bottomlistItem}>
                  <Link
                    component={RouterLink}
                    to="/cookies"
                    className={classes.footerLink}
                  >
                    cookies
                  </Link>
                </li>
                <li className={classes.bottomlistItem}>
                  <Link
                    component={RouterLink}
                    to="/requestergws"
                    className={classes.footerLink}
                  >
                    popi request
                  </Link>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Container>
      </Typography>
      <Grid
        spacing={5}
        className={`${classes.listbottom} ${classes.background}`}
        xs={12}
        sm={12}
        md={12}
      >
        <Grid xs={12} sm={6} md={8}></Grid>
        <Grid xs={12} sm={6} md={3} className={classes.footerLink} align="end">
          <Copyright />
        </Grid>
      </Grid>
      {!isMobile && (
        <div>
          <div className={classes.stickyBar} />
          <div className={classes.stickyBarInner}>
            <img src="endorsement.jpg" className={classes.stickyBarImage} />
          </div>
        </div>
      )}
    </Fragment>
  );
}
