import React, { useState, useCallback, useRef, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
// import Color from 'color';
import { DealCard } from "./DealCard";
import DealsFilter from "./DealsFilter";
import { useHistory } from "react-router-dom";
import { CurrencyValue } from "../shared/TextMask";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FinanceContext } from "../shared/contexts/FinanceContext";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  actionArea: {
    borderRadius: 16,
    height: "100%",
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  card: ({ color }) => ({
    minWidth: 256,
    height: "100%",
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      // boxShadow: `0 6px 12px 0 ${Color(color)
      //   .rotate(-12)
      //   .darken(0.2)
      //   .fade(0.5)}`,
    },
  }),
  content: ({ color }) => {
    return {
      backgroundColor: "#EFDF00",
      padding: "1rem 1.5rem 1.5rem",
      textAlign: "center",
      height: "100%",
    };
  },
  title: {
    fontSize: "2rem",
    color: "black",
    textTransform: "uppercase",
  },
  subtitle: {
    color: "black",
    opacity: 0.87,
    marginTop: "1.2rem",
    fontWeight: 500,
    fontSize: 20,
  },
  image: {
    minHeight: 200,
  },
}));

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.up("md")]: {
      // justifyContent: 'center',
    },
  },
}));

export default function SpecialDealsComponent() {
  const classes = useStyles();
  const gridStyles = useGridStyles({ color: "#ff9900" });
  const history = useHistory();
  const [sortDirection, setSortDirection] = useState("Desc");
  const [pageNumber, setPageNumber] = useState(1);
  const { loading, deals, hasMore } = DealsFilter(pageNumber, sortDirection);
  const observer = useRef();
  let { globalDealerVehicles } = useContext(FinanceContext);

  const lastDealRef = useCallback(
    (node) => {
      // if (loading) return
      // if (observer.current) {
      //   observer.current.disconnect()
      // }
      // observer.current = new IntersectionObserver(entries => {
      //   if (entries[0].isIntersecting && hasMore) {
      //     setPageNumber(prevPageNumber => prevPageNumber + 1)
      //   }
      // })
      // if (node) {
      //   observer.current.observe(node)
      // }
    },
    [loading, hasMore]
  );

  const cleanModel = (model) => {
    return model.toLowerCase().replace(/\//g, "_").replace(/ /g, "_");
  };

  return (
    <React.Fragment>
      <main>
        <Container maxWidth="lg">
          <div className={classes.background}>
            <Typography
              gutterBottom
              variant="h4"
              color="textPrimary"
              fontWeight="500"
            >
              <Box
                fontWeight={500}
                mb={5}
                style={{ textTransform: "capitalize" }}
              >
                Current financed offers
              </Box>
            </Typography>
            <Grid classes={gridStyles} container spacing={4}>
              {!loading &&
                deals.length > 0 &&
                deals.map((deal) => (
                  <Grid
                    item
                    key={deal.id}
                    xs={12}
                    sm={6}
                    md={4}
                    style={{ minHeight: 400 }}
                  >
                               {console.log("deal",deal)}
                    <DealCard
                      ref={lastDealRef}
                      onClick={() =>
                        history.push(`special-deals/${deal.model.toLowerCase()}/${deal.ownedModelId}/${deal.id}`)
                      }
                      classes={classes}
                      title={deal.headline}
                      subtitle={
                        <CurrencyValue
                          value={parseInt(deal.monthlyInstallment)}
                        />
                      }
                      image={deal.imageUrl}
                    />
                  </Grid>
                ))}
              {!loading && deals.length && deals.length === 0 && (
                <Grid item xs={12}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    color="textPrimary"
                    fontWeight="500"
                  >
         
                    <Box style={{ textTransform: "capitalize" }}>
                      No available deals
                    </Box>
                  </Typography>
                </Grid>
              )}
              {loading && (
                <Grid
                  item
                  container
                  justify="center"
                  alignContent="center"
                  xs={12}
                >
                  <CircularProgress size={80} />
                </Grid>
              )}
            </Grid>
          </div>
        </Container>
      </main>
    </React.Fragment>
  );
}
