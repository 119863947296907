import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import BuildIcon from "@material-ui/icons/Build";
import OurDealersController from "./OurDealersController";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  dividerTitle: {
    backgroundColor: "#EFDF00",
    width: "33%",
    marginBottom: "30px",
    height: "3px",
    margin: "20px auto",
  },
  dividerDealer: {
    backgroundColor: "#EFDF00",
    width: "33%",
    marginBottom: "15px",
    marginTop: "15px",
    height: "2px",
  },
  fontSmall: {
    fontSize: "0.9rem",
  },
  sideBorder: {
    borderLeft: "1px solid #ccc",
    padding: "0 10px",
    marginBottom: "20px",
    "@media (max-width: 959px)": {
      borderLeft: "none",
      width: "100%",
    },
  },
}));

const OurDealers = () => {
  const classes = useStyles();
  const { dealers } = OurDealersController();

  return (
    <Container maxWidth="lg">
      <div className={classes.content}>
        <Typography
          gutterBottom
          variant="h5"
          align="center"
          color="textPrimary"
          fontWeight="500"
        >
          OUR DEALER NETWORK
        </Typography>
        <Divider className={classes.dividerTitle} />
        <Box mb={6} mt={6}>
          <Typography align="center" variant="h5">
            We have a network of dealers to choose from to arrange delivery
            which is closest to you
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {dealers
            .filter((x) => x.name.toLowerCase().indexOf("master") === -1)
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((dealer) => {
              let mapUrl = `https://maps.google.com/maps?q=${dealer.addressLongitude},${dealer.addressLatitude}&z=16&output=embed`;
              //let mapUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyAABYsdUoaIiR-U4j_kXIResQeWOvXOEHk&q=${dealer.addressLongitude},${dealer.addressLatitude}`;

              let businessHours = !!dealer.businessHours
                ? dealer.businessHours
                : {
                    weekday: "Monday - Friday: 8:00 - 17:00",
                    weekend: "Saturday: 8:00 - 13:00",
                  };
              let services =
                dealer.services && dealer.services.lengh > 0
                  ? dealer.services
                  : ["Sales", "Service"];

              return (
                <Grid
                  item
                  sm={12}
                  md={4}
                  key={dealer.dealerId}
                  className={classes.sideBorder}
                >
                  <Box>
                    <Typography variant="h6">{dealer.name}</Typography>
                    <Divider className={classes.dividerDealer} />
                    <Box fontWeight="fontWeightBold">Address</Box>
                    <Box className={classes.fontSmall}>
                      {!!dealer.address?.streetAddress
                        ? dealer.address.streetAddress
                        : dealer.address?.postalAddress}
                    </Box>
                    <Box mt={2} fontWeight="fontWeightBold">
                      Business Hours
                    </Box>
                    <Box className={classes.fontSmall}>
                      {businessHours?.weekday}
                    </Box>
                    <Box className={classes.fontSmall}>
                      {businessHours?.weekend}
                    </Box>
                    <Box style={{ display: "flex", marginBottom: "15px" }}>
                      {services?.map((service, index) => {
                        return (
                          <Box
                            key={index}
                            style={{ display: "flex", alignItems: "center" }}
                            mr={1}
                          >
                            {service === "Sales" && <DriveEtaIcon />}
                            {service === "Service" && <BuildIcon />}
                            <span className={classes.fontSmall}>{service}</span>
                          </Box>
                        );
                      })}
                    </Box>
                    <amp-iframe
                      key={dealer.dealerId}
                      width="644"
                      height="244"
                      layout="responsive"
                      sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                      frameborder="0"
                      src={mapUrl}
                      className="i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                      i-amphtml-layout="responsive"
                      style={{ "--loader-delay-offset": "419ms !important" }}
                    >
                      <i-amphtml-sizer
                        slot="i-amphtml-svc"
                        style={{ paddingTop: "60.8882%" }}
                      ></i-amphtml-sizer>
                      <amp-img
                        src={mapUrl}
                        width="600"
                        height="600"
                        layout="responsive"
                        placeholder=""
                        className="i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout amp-notsupported amp-hidden"
                        i-amphtml-layout="responsive"
                      >
                        <i-amphtml-sizer
                          slot="i-amphtml-svc"
                          style={{ paddingTop: "100%" }}
                        ></i-amphtml-sizer>
                        <img
                          decoding="async"
                          src={mapUrl}
                          className="i-amphtml-fill-content i-amphtml-replaced-content i-amphtml-ghost"
                        />
                      </amp-img>
                      <i-amphtml-scroll-container className="amp-active">
                        <iframe
                          className="i-amphtml-fill-content"
                          name="amp_iframe0"
                          frameborder="0"
                          allow=""
                          sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                          src={mapUrl}
                          style={{ zIndex: 0 }}
                        ></iframe>
                      </i-amphtml-scroll-container>
                    </amp-iframe>
                  </Box>
                </Grid>
              );
            })}
        </Grid>

        {/* <Grid container spacing={2}>
          {Dealers.map((dealer, index) => {
            return (
              <Grid
                item
                sm={12}
                md={4}
                key={index}
                className={classes.sideBorder}
              >
                <Box>
                  <Typography variant="h6">{dealer.dealer}</Typography>
                  <Divider className={classes.dividerDealer} />
                  <Box fontWeight="fontWeightBold">Address</Box>
                  <Box className={classes.fontSmall}>{dealer.address}</Box>
                  <Box mt={2} fontWeight="fontWeightBold">
                    Business Hours
                  </Box>
                  <Box className={classes.fontSmall}>
                    {dealer.businessHours.weekday}
                  </Box>
                  <Box className={classes.fontSmall}>
                    {dealer.businessHours.weekend}
                  </Box>
                  <Box style={{ display: "flex", marginBottom: "15px" }}>
                    {dealer.services.map((service, index) => {
                      return (
                        <Box
                          key={index}
                          style={{ display: "flex", alignItems: "center" }}
                          mr={1}
                        >
                          {service === "Sales" && <DriveEtaIcon />}
                          {service === "Service" && <BuildIcon />}
                          <span className={classes.fontSmall}>{service}</span>
                        </Box>
                      );
                    })}
                  </Box>
                  {dealer.map}
                </Box>
              </Grid> 
            );
          })}
        </Grid>*/}
      </div>
    </Container>
  );
};

export default OurDealers;
