export const BASE_URI = process.env.REACT_APP_CONEXA_API_URL;
export const PAGE_SIZE = 20;
export const MOTORGROUPID = 1;
export const DEALERMAKEID = 8013;
export const QUALIFYING_FINANCE_PERCENTAGE = 0.33;
export const DEALERID = 6;
export const VIRTUAL_URI_RANGE = `https://configurator.renaultvirtual.co.za/${DEALERID}/range/:leadId`;
export const VIRTUAL_URI_DERIVATIVE = `https://configurator.renaultvirtual.co.za/${DEALERID}/:modelId/builder/:leadId`;
export const VIRTUAL_URI_APPLICATION = `https://configurator.renaultvirtual.co.za/${DEALERID}/1/vehicle/finance/0/:leadId`;


export const FINAPP_URI_BASE = "https://dz6rhlcy38qhy.cloudfront.net";
export const FINAPP_URI_APPLICATION = `${FINAPP_URI_BASE}/${DEALERID}/1/vehicle/finance/0/:leadId`;