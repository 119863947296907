import React, { useContext } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import contactUsImage from "../shared/assets/Contact-Us.jpg";
import { ImageContainer } from "../shared/ImageContainer";
import ContactComponent from "../shared/ContactComponent";
import ContactDetailsController from "./ContactDetailsController";
import { FinanceContext } from "../shared/contexts/FinanceContext";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
  dividerTitle: {
    backgroundColor: "#EFDF00",
    width: "33%",
    marginBottom: "0px",
    height: "3px",
    margin: "20px auto",
  },
  bold: {
    fontWeight: 700,
  },
  url: {
    color: "#7c7c7c",
    textDecoration: "none",
    "&:hover": {
      color: "#1f2532",
      textDecoration: "none",
    },
  },
  link: {
    backgroundColor: "#EFDF00",
    marginBottom: "1rem",
    color: "#343232",
    fontWeight: "700",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const ContactUs = (props) => {
  console.log("propsC",props)
  const vehicle = props.location.state
  const classes = useStyles();
  const { globalDealerContactDetails } = useContext(FinanceContext);
  return (
    <Container maxWidth="lg">
      <Box>
        <ImageContainer
          aspectRatio={16 / 6}
          height="100%"
          src={contactUsImage}
          alt="Contact Us"
        />
      </Box>
      <div className={classes.content}>
        <Typography
          gutterBottom
          variant="h5"
          align="center"
          color="textPrimary"
          fontWeight="500"
        >
          Our Customer Relations team are here to help you
        </Typography>
        <Divider className={classes.dividerTitle} />
        <Box mb={3} mt={3}>
          <Box textAlign="center" fontSize={"1.5rem"}>
            Send us your details and we will call you
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box fontWeight="fontWeightBold">Customer Care Contact Centre</Box>
            <Box mb={3}>{globalDealerContactDetails.newCarsPhoneNumber}</Box>
            <Box fontWeight="fontWeightBold">Live Chat</Box>
            <Box mb={3}>Monday - Friday 08:00 - 17:00</Box>
            <Box fontWeight="fontWeightBold">Email</Box>
            <Box mb={3}>
              <a
                className={classes.url}
                href={`mailto:${globalDealerContactDetails.email}?Subject=Renault%20Virtual%20Contact`}
                title="Drop us message on email"
              >
                {globalDealerContactDetails.email}
              </a>
            </Box>
            <Box fontWeight="fontWeightBold">Business Hours</Box>
            <Box>Monday - Friday 08:00 - 17:00</Box>
            <Box>Saturday: 08:00 - 13:00</Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactComponent {...vehicle}/>
          </Grid>
          <Grid item xs={12} md={3}>
            <Link
              className={classes.link}
              component={RouterLink}
              to="/apply-for-finance"
            >
              <Box textAlign="center" pt={1} pb={1} className={classes.link}>
                Apply for Finance
              </Box>
            </Link>
            <Link className={classes.link} component={RouterLink} to="/#">
              <Box textAlign="center" pt={1} pb={1} className={classes.link}>
                Book a Test Drive
              </Box>
            </Link>
            <Link
              className={classes.link}
              component={RouterLink}
              to="/special-deals"
            >
              <Box textAlign="center" pt={1} pb={1} className={classes.link}>
                Special Deals
              </Box>
            </Link>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default ContactUs;
