import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { BASE_URI, DEALERID } from "../shared/Constants";
import { FinanceContext } from "../shared/contexts/FinanceContext";

export default function OurDealersController() {
  const [loading, setLoading] = useState(true);
  const [dealerContactDetails, setDealerContactDetails] = useState({});
  const [dealerSocialMedia, setDealerSocialMedia] = useState({});

  let { setGlobalDealerContactDetails } = useContext(FinanceContext);

  useEffect(() => {
    let cancel;
    setLoading(true);
    setDealerContactDetails({});
    setGlobalDealerContactDetails({});
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios
      .get(`${BASE_URI}/Dealers/DealerContactDetails/${DEALERID}`, {
        cancelToken: source.token,
      })
      .then((response) => {
        setDealerContactDetails(response?.data);
        setGlobalDealerContactDetails(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        setLoading(false);
      });

    axios
      .get(`${BASE_URI}/DealerSocialMedia/${DEALERID}`, {
        cancelToken: source.token,
      })
      .then((response) => {
        setDealerSocialMedia(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        setLoading(false);
      });
  }, [DEALERID]);

  return { loading, dealerContactDetails, dealerSocialMedia };
}
