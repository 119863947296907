import React, { useState, useRef, useEffect, useContext } from "react";
import axios from "axios";
import { BASE_URI } from "../shared/Constants";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FinanceContext } from "../shared/contexts/FinanceContext";

export default function CarProfileController(props) {
  const { state } = props.location.state;
  let { carId, dealId } = useParams();

  const [colors, setColours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [colorsLoading, setColorsLoading] = useState(true);
  const mountedRef = useRef(true);
  const { enqueueSnackbar } = useSnackbar();
  const [financeDetails, setFinanceDetails] = useState({});
  const [variantList, setVariantList] = React.useState([]);
  const [modelInfo, setModelInfo] = useState(null);
  const [modelData, setModelData] = useState(null);
  const [variantId, setVariantId] = React.useState(0);
  const [colorData, setColorData] = React.useState([]);
  const [selectedColor, setSelectedColor] = React.useState();
  const [colorId, setColorId] = React.useState(0);
  const [relatedModel, setRelatedModel] = useState(null);

  let {
    setGlobalFinance,
    setGlobalVehicle,
    setGlobalColor,
    setGlobalVariantId,
    setGlobalModel,
    globalColor,
    globalVehicles,
    globalDealerVehicles,
  } = useContext(FinanceContext);

  useEffect(() => {
    setLoading(true);
    setModelInfo(null);
    let relatedModel;
    if (!state?.model) {
      if (globalDealerVehicles) {
        let modelName = carId.toLowerCase().replace(/\-/g, " ").trim();
        relatedModel = globalDealerVehicles.filter(
          (x) => x.model.toLowerCase().trim() == modelName
        )[0];
        setRelatedModel(relatedModel);
      }

      if (!state?.model && !relatedModel) {
        setLoading(false);
        return;
      }
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios
      .get(
        `${BASE_URI}/dealervariants?dealerModelId=${
          state?.model?.id ?? relatedModel?.id
        }`,
        {
          cancelToken: source.token,
        }
      )
      .then((result) => {
        let data = result.data.list;
        setVariantList(data);
        let firstVariant = data[0];

        setModelData({
          price:
            firstVariant.priceRange != 0
              ? firstVariant.priceRange
              : firstVariant.modelPrice,
          id: firstVariant.modelId,
        });

        setVariantId(firstVariant.id);
      });

    axios
      .get(
        `${BASE_URI}/DealerModelInformation/GetForDealerModel/${
          state?.model?.id ?? relatedModel?.id
        }`,
        {
          cancelToken: source.token,
        }
      )
      .then((result) => {
        if (result?.data) {
          setModelInfo(result?.data);
          setGlobalModel(result?.data);
        }
        setLoading(false);
      });
  }, [carId, state?.model]);

  useEffect(() => {
    setSelectedColor(null);
    if (!variantId || variantId == 0) {
      return;
    }

    setGlobalVariantId(variantId);
    setColorsLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const result = axios
      .get(`${BASE_URI}/colours/exteriors?dealerVariantId=${variantId}`, {
        cancelToken: source.token,
      })
      .then((result) => {
        let data = result?.data?.list;
        setColorData(data);

        setColours(
          data.map((itm) => {
            return { img: itm.image, id: itm.id };
          })
        );

        setColorsLoading(false);
      })
      .catch((error) => {
        console.error(
          "Failed to get exterior colors for variant ID:",
          variantId,
          error
        );
        source.cancel();
        setColorsLoading(false);
      });
  }, [variantId]);

  const handleModelChange = async (e, value, setFieldValue) => {
    setFieldValue("model", e.target.value);
    setFieldValue("variantId", value.props.id);
    setVariantId(parseInt(value.props.id));

    var variant = variantList.filter((itm) => itm.id == value.props.id)[0];

    var tmpFinanceDetails = {
      ...financeDetails,
      purchasePrice: variant.priceRange,
    };

    let monthly = calculateMonthly(tmpFinanceDetails);

    tmpFinanceDetails = { ...tmpFinanceDetails, monthlyInstallment: monthly };

    setFinanceDetails(tmpFinanceDetails);
    setGlobalFinance(tmpFinanceDetails);
    setGlobalModel(variant);
  };

  const calculateMonthly = (data) => {
    var deposit = data.purchasePrice * (data.deposit / 100);
    var totalPrice = data.purchasePrice;
    var initFee = 0;
    var principal = totalPrice - deposit + initFee;

    var balloonPerc = data.balloonPayment;

    var balloonAmt = (totalPrice * balloonPerc) / 100;

    var interestRate = data.linkedInterestRate;
    var interestPM = interestRate / 100 / 12;

    var repaymentPeriod = data.term;
    var days = 1;

    var v = 1 / (1 + interestPM);
    var d = 1 - v;
    var y = Math.pow(v, repaymentPeriod - 1);
    var comp = (1 - y) / d;
    var fp = principal * Math.pow(1 + interestPM, days / (365 / 12).toFixed());
    var monthly = 0;

    if (parseInt(balloonAmt) > 0) {
      var comp = (1 - Math.pow(v, repaymentPeriod - 1)) / d;
      monthly = (fp - balloonAmt * Math.pow(v, repaymentPeriod - 1)) / comp;
    } else {
      var comp = (1 - Math.pow(v, repaymentPeriod)) / d;
      monthly = fp / comp;
    }

    return round(monthly) + initFee;
  };

  function round(x) {
    return Math.round(x * 100) / 100;
  }

  useEffect(() => {
    if (!modelInfo || !modelData) {
      return;
    }

    setLoading(true);
    const CancelToken = axios.CancelToken;

    const source = CancelToken.source();
    const getOffer = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/Offers/${dealId}`, {
          cancelToken: source.token,
        });
        let data = result.data;

        let tmpFinanceDetails = {
          purchasePrice: data.price,
          monthlyInstallment: data.monthlyInstallment,
          term: data.term,
          linkedInterestRate: data.rate,
          deposit: data.deposit,
          balloonPayment: data.balloonPayment,
          termsAndConditions: data.termsAndConditions,
          dealDescription: data.metaDescription,
        };
        setFinanceDetails(tmpFinanceDetails);
        setGlobalFinance(tmpFinanceDetails);
      } catch (error) {
        setLoading(false);
        enqueueSnackbar("Unable to get offer details", { variant: "error" });
      }
    };

    if (dealId) {
      getOffer().then(() => {
        window.scrollTo(0, 0);

        setLoading(false);

        if (!mountedRef.current) return null;
      });
    } else {
      let financeDetails = {
        balloonPayment:
          modelInfo.dealer?.balloonPayment ?? modelInfo.deal.balloonPayment,
        deposit: modelInfo.dealer?.deposit ?? modelInfo.deal?.deposit,
        linkedInterestRate:
          modelInfo.dealer?.offerInterestRate ?? modelInfo.deal?.rate,
        term: 72,
        purchasePrice: modelData.price,
        specs: modelInfo.features,
      };

      let monthly = calculateMonthly(financeDetails);
      financeDetails.monthlyInstallment = monthly;

      setFinanceDetails(financeDetails);
      setGlobalFinance(financeDetails);
      setLoading(false);
    }

    return () => {
      mountedRef.current = false;
      source.cancel();
    };
  }, [carId, dealId, modelInfo, modelData]);

  useEffect(() => {
    setSelectedColor(null);
    if (colorId == 0) {
      return;
    }

    var tmpFinanceDetails;
    var color = colorData.filter((itm) => itm.id == colorId)[0];

    if (color.price > 0) {
      tmpFinanceDetails = { ...financeDetails, purchasePrice: color.price };
    } else {
      tmpFinanceDetails = {
        ...financeDetails,
        purchasePrice: modelData.price,
      };
    }

    let monthly = calculateMonthly(tmpFinanceDetails);

    tmpFinanceDetails = { ...tmpFinanceDetails, monthlyInstallment: monthly };

    setFinanceDetails(tmpFinanceDetails);
    setGlobalFinance(tmpFinanceDetails);

    setGlobalColor(color);
    setSelectedColor(color);
  }, [colorId]);

  return {
    state,
    loading,
    colorsLoading,
    variantList,
    colorData,
    colors,
    modelInfo,
    dealId,
    relatedModel,
    colorId,
    setColorId,
    handleModelChange,
    financeDetails,
    selectedColor,
  };
}
