import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { CurrencyValue } from "./TextMask";

const useStyles = makeStyles((theme) => ({
  featureDeal: {
    borderBottom: "1px solid #cccccc",
    "&:last-child": {
      borderBottom: 0,
    },
  },
  headerLine: {
    height: "1.5px",
    width: "50%",
    backgroundColor: "#EFDF00",
    padding: "0 !important",
  },
  paymentDetails: {
    "& > div": {
      borderBottom: "1px solid #cccccc",
      padding: ".8rem .5rem",
      display: "flex",
      justifyContent: "space-between",
    },
  },
}));

const PaymentDetails = ({
  details,
  onClick,
  displayPrice,
  displayDetails,
  displaySpecs,
  displayTermsAndConditions,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.featureDeal}>
      <Grid container spacing={4}>
        {details.image && (
          <Grid item xs={12} sm={6} md={6}>
            <Box height={310} width={"100%"}>
              <img
                style={{ objectFit: "cover", width: "100%", height: "100%" }}
                src={details.image}
              />
            </Box>
          </Grid>
        )}

        <Grid
          className={classes.paymentDetails}
          item
          xs={12}
          md={details.image ? 6 : 12}
        >
          {displayPrice && (
            <React.Fragment>
              <Typography variant="h4" color="textPrimary">
                From{" "}
                <strong>
                  <CurrencyValue value={details.purchasePrice} />
                </strong>{" "}
                or{" "}
                <strong>
                  <CurrencyValue value={details.monthlyInstallment} />
                  pm
                </strong>
              </Typography>
              <div className={classes.headerLine}></div>
            </React.Fragment>
          )}
          {displayDetails && (
            <React.Fragment>
              <Box display="flex" mt={3} pb={1}>
                <Box width={200}>
                  <Typography>
                    <strong>Purchase Price</strong>
                  </Typography>
                </Box>
                <Typography>
                  <CurrencyValue value={details.purchasePrice} />
                </Typography>
              </Box>
              <Box display="flex" pb={1}>
                <Box width={200}>
                  <Typography>
                    <strong>Monthly Instalment</strong>
                  </Typography>
                </Box>
                <Typography>
                  <CurrencyValue value={details.monthlyInstallment} />
                </Typography>
              </Box>
              <Box display="flex" pb={1}>
                <Box width={200}>
                  <Typography>
                    <strong>Term (Months)</strong>
                  </Typography>
                </Box>
                <Typography>{details.term}</Typography>
              </Box>
              <Box display="flex" pb={1}>
                <Box width={200}>
                  <Typography>
                    <strong>Linked Interest Rate</strong>
                  </Typography>
                </Box>
                <Typography>{details.linkedInterestRate}%</Typography>
              </Box>
              <Box display="flex" pb={1}>
                <Box width={200}>
                  <Typography>
                    <strong>Deposit</strong>
                  </Typography>
                </Box>
                <Typography>{details.deposit}%</Typography>
              </Box>
              <Box display="flex" pb={1}>
                <Box width={200}>
                  <Typography>
                    <strong>Balloon Payment</strong>
                  </Typography>
                </Box>
                <Typography>{details.balloonPayment}%</Typography>
              </Box>
            </React.Fragment>
          )}
          {onClick && (
            <Button variant="contained" onClick={onClick} color="primary">
              Discover Offer
            </Button>
          )}
        </Grid>

        {displaySpecs && details.specs && details.specs.length > 0 && (
          <Grid
            item
            container
            className="specs"
            style={{ borderBottom: "1px solid #cccccc" }}
          >
            {details.specs.map((spec) => {
              return (
                <Grid key={spec.id} item xs={6} md={3}>
                  <Typography align="center" variant="h6">
                    {spec.title}
                  </Typography>
                  <Typography align="center" color="textPrimary">
                    {spec.description}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        )}
        {displayTermsAndConditions && (
          <Typography
            style={{ marginTop: ".5rem" }}
            variant="caption"
            color="textSecondary"
          >
            {details.termsAndConditions}
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

PaymentDetails.defaultProps = {
  displayDetails: true,
  displayPrice: true,
};

export default PaymentDetails;
