import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
  DotGroup,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    position: "relative",
  },
  carouselBack: {
    left: "10px",
    background: "#000000",
    borderRadius: "50%",
    height: "40px",
    width: "40px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%);",
    all: "unset",
    cursor: "pointer",
    "@media (max-width: 425px)": {
      top: "60%",
    },
  },
  carouselNext: {
    right: "10px",
    background: "#000000",
    borderRadius: "50%",
    height: "40px",
    width: "40px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%);",
    all: "unset",
    cursor: "pointer",

    "@media (max-width: 425px)": {
      top: "60%",
    },
  },
  carouselDots: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
    marginBottom: "15px",
    "& > button": {
      width: "15px",
      height: "15px",
      borderRadius: "50%",
      border: "none",
      padding: "0",
      cursor: "pointer",
      backgroundColor: "#EFDF00",
      marginRight: "10px",
    },
  },
  backgroundImage: {
    backgroundPosition: "center center",
    backgroundSize: "100% 100%!important",
    "@media (max-width: 624px)": {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top",
      height: "36%",
    },
  },
  // slide: {
  //     backgroundColor: "#cccccc",
  // },
}));

const Carousel = ({ slides, innerClass, dots, onSlideClick, ...props }) => {
  const classes = useStyles();
  return (
    <CarouselProvider {...props}>
      <div className={classes.sliderContainer}>
        <Slider>
          {slides &&
            slides.map((slide, index) => (
              <Slide
                innerClassName={innerClass}
                className={classes.slide}
                index={index}
                onClick={onSlideClick ? () => onSlideClick(index) : () => {}}
              >
                <Link to={slide?.content?.props?.url}>
                
                {slide.content}
                {slide.content ? (
                  <Image
                    tag="div"
                    isBgImage
                    src={slide.img ? slide.img : slide}
                    className={classes.backgroundImage}
                  />
                ) : (
                  <Image src={slide.img ? slide.img : slide} />
                )}
                </Link>
              </Slide>
            ))}
        </Slider>
        {dots && (
          <DotGroup
            className={classes.carouselDots}
            disableActiveDots={false}
          />
        )}
        <ButtonBack className={classes.carouselBack}>
          <ChevronLeftIcon style={{ fontSize: 40, color: "#EFDF00" }} />
        </ButtonBack>
        <ButtonNext className={classes.carouselNext}>
          <ChevronRightIcon style={{ fontSize: 40, color: "#EFDF00" }} />
        </ButtonNext>
      </div>
    </CarouselProvider>
  );
};

export default Carousel;
