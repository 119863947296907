import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';


import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box';

import axios from 'axios';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
// import Color from 'color';
import { DealCard } from './DealCard'
import DealsFilter from './DealsFilter'
import ContactComponent from '../shared/ContactComponent'
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import {
  useParams
} from "react-router-dom";
import { CurrencyValue } from '../shared/TextMask'
import CircularProgress from '@material-ui/core/CircularProgress';
import carouselImage from './../shared/assets/home-slide-1.jpg';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  holder: {
    display: 'flex',
    padding: '40px 20px',
    maxWidth: '1280px',
    margin:'0 auto',
    justifyContent:"space-around",
    '@media (max-width: 780px)': {
      flexDirection: 'column',
      padding: theme.spacing(10, 2, 6),
    }
  },

  image: {
    minHeight: 320,
    backgroundSize: "100% 100%"
  },
  paymentDetails: {
    '& > div': {
      borderBottom: '1px solid #cccccc',
      padding: '.8rem .5rem',
      display: 'flex',
      justifyContent: 'space-between'
    }
  }
}));

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.up('md')]: {
      // justifyContent: 'center',
    },
  },
}));


export default function SpecialDealsComponentDetailed(props) {
  const [pageNumber, setPageNumber] = useState(1)
  const [sortDirection, setSortDirection] = useState('Desc')
  const {
    loading,
    deals,
    unfilterdeals,
    hasMore
  } = DealsFilter(pageNumber, sortDirection)
  const classes = useStyles();
  const gridStyles = useGridStyles({ color: '#ff9900' });
  const history = useHistory();

  const [dealData, setDealData] = useState(deals)
  const [activeDeal, setActiveDeal] = useState([])
  const [dropDown, setDropDown] = useState(false)
  const [cashDeal, setCashDeal] = useState(false)
  const [hide, setHide] = useState(false)
  
  const [selectedVehicle, setSelectedVehicle] = useState('');

  const { carId, modelId, dealId } = useParams();



  const observer = useRef()
  const lastDealRef = useCallback(node => {

  }, [loading, hasMore])

  const cleanModel = (model) => {

    return model.toLowerCase().replace('/', '-')
  }



  useEffect(() => {

    let results = deals?.find(deal => deal.id == dealId)
    console.log("results",results)
    setActiveDeal(results)
  }, [deals, dealId])


  useEffect(() => {

    if(dealId == 2060){
      setHide(true)
      console.log("dealId",dealId)
    }
    console.log("deals",deals)
 
    
  }, [deals])
  

  var format = /[ (]/;

  const handleChange = (event, child) => {

    setSelectedVehicle(event.target.value);
  };

  useEffect(() => {

    if (parseInt(activeDeal?.metaKeywords?.split(",")[0]) <= 999) {
      setDropDown(true)

    } else {

      setDropDown(false)
    }


  }, [activeDeal])
  const params = useParams()

  useEffect(() => {

    if (history.location.pathname.toLowerCase().includes("campaigns".toLowerCase())) {
      history.push(`/campaigns/${carId}/${modelId}/${dealId}`)
    } else {

    }



  }, [deals])

  return (
    <React.Fragment>
      <main>
        <div className={classes.holder}>



          <Grid item xs={12} sm={12} md={7} lg={7} >
            <Typography
              gutterBottom
              variant="h5"
              align="left"
              color="textPrimary"
              fontWeight="500"
            >
              {selectedVehicle ? selectedVehicle?.headline : activeDeal?.headline}
            </Typography>
            {/* <Typography
                        gutterBottom
                        variant="h6"
                        align="left"
                        color="textPrimary"
                        fontWeight="500"
                >
                     Only Valid Until: {activeDeal?.dateTo?.slice(0,10)}
            </Typography> */}
            <img src={selectedVehicle ? selectedVehicle?.imageUrl : activeDeal?.imageUrl} width="100%" />
            {(activeDeal?.leadType !== "Service" || activeDeal?.leadType !== "Parts") &&
              <>
                <Typography
                  gutterBottom
                  variant="h5"
                  align="left"
                  color="textPrimary"
                  fontWeight="500"
                >
                  {selectedVehicle ? selectedVehicle?.make : activeDeal?.make} {selectedVehicle ? selectedVehicle?.model : activeDeal?.model}
                </Typography>

                <Typography
                  gutterBottom
                  variant="h5"
                  align="left"
                  color="textPrimary"
                  fontWeight="500"
                >

                  {selectedVehicle ? selectedVehicle?.variant?.substring(0, activeDeal?.variant?.indexOf("(") - 1) : activeDeal?.variant?.substring(0, activeDeal?.variant?.indexOf("(") - 1)}
                </Typography>
              </>
            }
            <Typography
              gutterBottom
              variant="p"
              align="left"
              color="textPrimary"
              fontWeight="500"
            >
              {selectedVehicle ? selectedVehicle?.introParagraph : activeDeal?.introParagraph}
            </Typography>
            <Grid className={classes.paymentDetails} item xs={12} >
              {activeDeal?.isCashDeal == false &&
                <React.Fragment>
                  <Box display="flex" mt={2} pb={1}>
                    <Typography variant="h5" color="textPrimary" >
                      <strong>
                        QUICK SUMMARY
                      </strong>
                    </Typography>
                  </Box>
                  <Box display="flex" pb={1}>
                    <Box width={200}><Typography><strong>Purchase Price</strong></Typography></Box>
                    <Typography><CurrencyValue value={selectedVehicle ? selectedVehicle?.price : activeDeal?.price} /></Typography>
                  </Box>
                  <Box display="flex" pb={1}>
                    <Box width={200}><Typography><strong>Monthly Installment</strong></Typography></Box>
                    <Typography><CurrencyValue value={selectedVehicle ? selectedVehicle?.monthlyInstallment : activeDeal?.monthlyInstallment} /></Typography>
                  </Box>
                  <Box display="flex" pb={1}>
                    <Box width={200}><Typography><strong>Term (Months)</strong></Typography></Box>
                    <Typography>{selectedVehicle ? selectedVehicle?.term : activeDeal?.term}</Typography>
                  </Box>
                  <Box display="flex" pb={1}>
                    <Box width={200}><Typography><strong>Linked Interest Rate</strong></Typography></Box>
                    {activeDeal?.rate == 0 &&
                      <Typography>0 %</Typography>
                    }
                    {activeDeal?.rate !== 0 &&
                      <Typography>{selectedVehicle ? selectedVehicle?.rate : activeDeal?.rate}%</Typography>

                    }

                  </Box>
                  <Box display="flex" pb={1}>
                    <Box width={200}><Typography><strong>Deposit</strong></Typography></Box>
                    {((dropDown && selectedVehicle?.deposit === 0) || (!dropDown && activeDeal?.deposit === 0)) &&
                      <Typography>0%</Typography>}

                    {((dropDown && selectedVehicle?.deposit !== 0) || (!dropDown && activeDeal?.deposit !== 0)) &&
                      <Typography>{dropDown && selectedVehicle?.deposit ? selectedVehicle?.deposit : activeDeal?.deposit}%</Typography>
                    }

                  </Box>
                  <Box display="flex" pb={1}>
                    <Box width={200}><Typography><strong>Balloon Payment</strong></Typography></Box>
                    {activeDeal?.balloonPayment == 0 &&
                      <Typography>0%</Typography>
                    }
                    {activeDeal?.balloonPayment !== 0 &&
                      <Typography>{selectedVehicle ? selectedVehicle?.balloonPayment : activeDeal?.balloonPayment}%</Typography>

                    }
                  </Box>
                </React.Fragment>
              }
            </Grid>
            <Typography
              gutterBottom
              variant="p"
              align="left"
              color="textPrimary"
              fontWeight="500"
              style={{ fontSize: "0.8rem", padding: "1rem 0px", display: 'block' }}
            >
              {selectedVehicle ? selectedVehicle?.termsAndConditions : activeDeal?.termsAndConditions}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={4}>

            <Typography
              gutterBottom
              variant="h5"
              align="left"
              color="textPrimary"
              fontWeight="500"
              style={{ padding: '0px 1rem' }}
            >
              Request A Call Back
            </Typography>
            {dropDown &&
              <Grid item xs={12} style={{ padding: '0px 16px' }} >

                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  id="Select a Model"
                  label="Select a Model"
                  value={selectedVehicle}
                  onChange={(e, value) => { handleChange(e, value) }}

                >

                  {
                    unfilterdeals?.filter((d) => d.metaKeywords.includes(activeDeal?.metaKeywords.split(",")[0]))?.map((deal) => {

                      return (

                        <MenuItem value={deal} style={{ padding: '5px 10px', background: 'none' }}>{deal?.variant}</MenuItem>


                      )
                    })
                  }

                </TextField>

              </Grid>
            }
            <ContactComponent 
            leadTypeId={(!dropDown && activeDeal?.leadTypeId) || (dropDown && selectedVehicle?.leadTypeId) || 3} 
            offerId={dropDown ? selectedVehicle?.offerId : activeDeal?.offerId} 
            dropDown={dropDown} 
            hide={hide}/>

            <Box mb={3} mt={0} style={{ background: "white", paddingBottom: '20px' }}>
              <Link href='/apply-for-finance' style={{ margin: '0px 1rem', background: "white", paddingBottom: '20px' }}>
                <Button className={classes.largeButtonDownload} variant="contained" color="primary" style={{ width: '93%' }}>
                  Apply Now
                </Button>
              </Link>
            </Box>
          </Grid>


        </div>
      </main>
    </React.Fragment>
  );
}