import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Banner from '../shared/assets/tomtom-banner.jpg'

const useStyles = makeStyles((theme) => ({
    bannerHeader: {
        backgroundColor: '#EFDF00',
        fontWeight: 700
    },
    info: {
        paddingBottom: '1rem'
    },
    link: {
        color: '#7c7c7c',
        textDecoration: 'none'
    }
}));

const TomTomPage = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                TOMTOM Live Support
            </Typography>
            <Box mb={2}>
                <img style={{ width: '100%' }} alt="Tom Tom" src={Banner} />
            </Box>
            <Typography gutterBottom variant="h6" color="textPrimary" fontWeight="500">
                A pioneer in built-in navigation systems, Renault launched its first satellite navigation equipment in 1995.
            </Typography>
            <div className={classes.info}>
                <Box>
                    <p>Since then, successive generations of satellite navigation systems have followed, incorporating the latest technological developments and
                    positioning Renault as the leader in built-in navigation systems available to the general public. To update or add content to your Carminat
                    TomTom see the latest offers on the
                        <a className={classes.link} href='https://www.tomtom.com/en_za/drive/maps-services/'> TOMTOM website.</a></p>
                </Box>
            </div>
            <div className={classes.info}>
                <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
                    Updates every quarter
                </Box>
                <Box>
                    <p>On average, 15% of roads change every year. That’s why map updates are available on TomTom HOME every three months.</p>
                </Box>
            </div>
            <div className={classes.info}>
                <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
                    Voices
                </Box>
                <Box>
                    <p>Select your favourite voice for your Carminat TomTom or Carminat TomTom LIVE device. Fun and entertaining, they add a touch of variety to
                        otherwise boring journeys.</p>
                </Box>
            </div>
            <div className={classes.info}>
                <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
                    Map Share<sup>TM</sup>
                </Box>
                <Box>
                    <p>Map Share<sup>TM</sup> technology improves navigation by allowing you to make instant corrections to your map, directly on your device. You can also
                        receive similar corrections made by the entire TomTom community of Map Share users.</p>
                </Box>
            </div>
            <div className={classes.info}>
                <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
                    New Navigation Software
                </Box>
                <Box>
                    <p>New software is available entirely free of charge via TomTom HOME, ensuring you can access the very latest functions.</p>
                </Box>
            </div>
            <div className={classes.info}>
                <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
                    Points of Interest
                </Box>
                <Box>
                    <p>You can download a host of additional points of interest using TOMTOM Home. The service offers a variety of practical, useful POIs,
                        as well as a range created by other users, for leisure stores, camp sites, car dealers and more.</p>
                </Box>
            </div>
            <div className={classes.info}>
                <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
                    TOMTOM Traffic
                </Box>
                <Box>
                    <p>Real-time traffic updates to get you to your destination faster.</p>
                    <ul>
                        <li>The biggest community in Europe for traffic information</li>
                        <li>Traffic data updated every 2 minutes</li>
                    </ul>
                </Box>
            </div>
        </React.Fragment>
    )
}

export default TomTomPage;