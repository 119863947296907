import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    link: {
        color: '#7c7c7c',
        textDecoration: 'none',
        '&:hover': {
            color: '#1f2532',
            textDecoration: 'none'
        }
    }
}));

const Terms = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="lg">
            <div className={classes.content}>
                <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500">
                    Our use of cookies
                </Typography>
                <p>
                    This website - Renault Virtual - uses cookies to allow us to see how our site is used. The cookies cannot identify you. If you continue to use this site we will assume that you are happy with this.
                </p>
                <p>
                    Cookies are small text files that are placed on your machine to provide a better user experience. In general, cookies are used to retain your preferences and provide anonymised tracking data to third party applications like Google Analytics.
                </p>
                <p>
                    However, you may prefer to disable cookies on this site and on others. The most effective way to do this is to disable cookies in your browser. We suggest consulting the Help section of your browser or taking a look at <a href="https://en.wikipedia.org/wiki/HTTP_cookie" target="_blank" className={classes.link}>Wikipedia</a> which offers guidance for all modern browsers
                </p>
                <p>
                    You may also want to read our <Link component={RouterLink} to="/terms" className={classes.link}>Terms & Conditions</Link>
                </p>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Contacting Us
                </Typography>
                <p>
                    If there are any questions regarding our Cookie Policy, <Link component={RouterLink} to="/contact-us" className={classes.link}>contact us</Link>.
                </p>
                <p>
                    Last Edited on 2018-10-11
                </p>
            </div>

            <div>
                <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
                    Additional Links
                </Typography>
                    <ul>
                        <li>
                            <Link component={RouterLink} to="/requestergws" className={classes.link}>Personal information request form</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/privacy" className={classes.link}>Policies</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/cookies" className={classes.link}>Cookie Policy</Link>
                        </li>
                        <li>
                            <Link component={RouterLink} to="/terms" className={classes.link}>Terms & Conditions</Link>
                        </li>
                    </ul>
                </div>
        </Container>
    )
}

export default Terms