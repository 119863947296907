import React,{useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import PaymentDetails from "../shared/PaymentDetails";
import { ImageContainer } from "../shared/ImageContainer";

import CarouselSingle from "../homePage/CarouselSingle";
import { SpecificationsComponent } from "../shared/SpecificationsComponent";
import { useParams, useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { CurrencyValue } from "../shared/TextMask";
import { FinanceContext } from "../shared/contexts/FinanceContext";
import CheckIcon from "@material-ui/icons/Check";
import ApplySteps from "../shared/ApplySteps";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";
import CarProfileController from "./CarProfileController";


const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  largeButton: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    width: "100%",
    "& h6": {
      fontSize: "28.8px",
    },
  },
  buttonIconLg: {
    fontSize: "5rem",
    color: "#EFDF00",
  },
  sectionPadding: {
    margin: "3rem 0",
  },
  paragraphPadding: {
    padding: "1.5rem 3rem !important",
  },
  heading: {
    borderBottom: "5px solid #EFDF00",
    textTransform: "capitalize",
    paddingBottom: ".5rem",
    fontWeight: "700",
    display: "inline-block",
  },
  yellowBorder: {
    borderBottom: "5px solid #EFDF00",
  },
  secondaryHeading: {
    textTransform: "uppercase",
    paddingBottom: "1rem",
    fontWeight: "400",
  },
  slideMargin: {
    width: "calc(100% - 20px)",
    height: "calc(100% - 20px)",
    left: "10px",
    top: "10px",
  },
  topHeader: {
    fontWeight: 700,
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: "2.5rem",
  },
  yellow: {
    color: "#EFDF00",
  },
  specs: {
    "& .specs": {
      borderBottom: "0 !important",
      marginTop: "-25px",
    },
  },
  formControl: {
    minWidth: 400,
    "@media (max-width: 450px)": {
      minWidth: "100%",
    },
  },
  headerLine: {
    height: "1.5px",
    width: "50%",
    backgroundColor: "#EFDF00",
    padding: "0 !important",
  },
  smallHeading: {
    fontSize: "1.3rem",
  },
  stepPaddingColours: {
    marginLeft: "1em",
    marginRight: "1em",
    marginTop: "0.5em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  colorsContainer: {
    display: "flex",
    "& > div:first-child": {
      marginLeft: 0,
    },
  },
  colourName: {
    textTransform: "uppercase",
    fontSize: "0.8rem",
    marginTop: "0.6rem",
  },
  colourPrice: {
    fontWeight: 700,
  },
  stepOneButtons: {
    display: "flex",
    "@media (max-width: 500px)": {
      flexDirection: "column",
      alignItems: "center",
      "& > a": {
        marginLeft: "0 !important",
        marginRight: "0 !important",
      },
    },
  },
  topDetailSection: {
    "@media screen and (max-width: 959px)": {
      display: "flex",
      flexDirection: "column",
      "& > div:first-child": {
        order: 2,
        marginTop: "2rem",
      },
      "& > div:nth-child(2)": {
        order: 3,
      },
      "& > div:nth-child(3)": {
        order: 4,
      },
      "& > div:nth-child(4)": {
        marginTop: "-1.5rem",
      },
    },
  },
}));

const CarProfileContainer = (props) => {
  const classes = useStyles();
  const {
    state,
    loading,
    colorsLoading,
    variantList,
    colorData,
    colors,
    modelInfo,
    dealId,
    relatedModel,
    colorId,
    setColorId,
    handleModelChange,
    financeDetails,
    selectedColor,
  } = CarProfileController(props);

  const [variant,setVariant] = useState(null);
 



  const history = useHistory();

  const values = {
    model: "",
    variantId: "",
  };

  const padZero = (str, len) => {
    len = len || 2;
    let zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
  };

  const invertColor = (hex) => {
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error("Invalid HEX color.");
    }
    // invert color components
    let r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
  };

  const stepsState = {
    one: {
      active: true,
      completed: false,
    },
    two: {
      active: true,
      completed: false,
    },
    three: {
      active: true,
      completed: false,
    },
  };


  
  const setActiveColor = (index) => {
    setColorId(colors[index].id);
  };

  const getContentImage = (type) => {
    return modelInfo?.contentImages && modelInfo?.contentImages?.length > 0
      ? modelInfo.contentImages.filter((x) => x.imageName == type)[0].image
          .imageUrl
      : "";
  };

  const getMainImage = () => {
    var image = getContentImage("main");

    if (selectedColor) {
      image = selectedColor.image;
    }

    return image;
  };

  const getGalleryImages = () => {
    if (modelInfo?.galleryImages && modelInfo?.galleryImages.length > 0) {
      return modelInfo.galleryImages.map((x) => x.image.imageUrl);
    }
    return [];
  };


  const handlePageChange = (path, vehicle) => {
 
    history.push(path, vehicle)
}

const vehicle = props.location.state

console.log('variant',variant)
console.log('vehicle',vehicle)

const model =  variant? variant : vehicle

const isVariantSelected = model?.variantId || model?.offerId ? true:false

console.log('model',model)



console.log('isVariantSelected',isVariantSelected)


  return (
    <React.Fragment>
      <main>
        <Container maxWidth="lg">
          {loading && (
            <Grid
              item
              container
              justifyContent="center"
              justify="center"
              alignContent="center"
              xs={12}
            >
              <CircularProgress size={80} />
            </Grid>
          )}
          {!loading && !modelInfo && (
            <Typography
              gutterBottom
              variant="h4"
              color="textPrimary"
              fontWeight="500"
              className={classes.singleHeader}
            >
              <Box mb={5} className={classes.topHeader}>
                {state?.model?.id > 0 || relatedModel?.id > 0
                  ? `Please load brochure information for the "${
                      state?.model?.fullModelName ?? relatedModel?.title
                    }" in DASH`
                  : "No linked dealer model found"}
              </Box>
            </Typography>
          )}
          {!loading && modelInfo && (
            <div className={classes.background}>
              <Typography
                gutterBottom
                variant="h4"
                color="textPrimary"
                fontWeight="500"
              >
                <Box mb={5} className={classes.topHeader}>
                  {!dealId ? (
                    <span>
                      <span className={classes.yellow}>Step 1</span> let's{" "}
                      <span className={classes.yellow}>configure</span> your car
                    </span>
                  ) : (
                    <span>{financeDetails.dealDescription}</span>
                  )}
                </Box>
              </Typography>

              <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
                <Grid item xs={12} md={6}>
                  <Box style={{ padding: " 0 0.6rem" }}>
                    {selectedColor ? (
                      <ImageContainer
                        aspectRatio={16 / 10}
                        src={getMainImage()}
                        alt="MainImage"
                      />
                    ) : (
                      <ImageContainer
                        aspectRatio={16 / 6}
                        src={getMainImage()}
                        alt="MainImage"
                      />
                    )}
                  </Box>
                  <Box style={{ marginBottom: "1rem" }}>
                    {!loading && colors.length > 0 && (
                      <CarouselSingle
                        naturalSlideWidth={100}
                        naturalSlideHeight={60}
                        totalSlides={colors.length}
                        visibleSlides={4}
                        isPlaying={true}
                        infinite
                        innerClass={classes.slideMargin}
                        slides={colors}
                        onSlideClick={(index) => setActiveColor(index)}
                      />
                    )}
                  </Box>
                  <Box className={classes.specs}>
                    <PaymentDetails
                      displayPrice={false}
                      displayDetails={false}
                      displaySpecs
                      details={financeDetails}
                    />
                  </Box>
                  <Box>
                    <Card
                      style={{
                        marginTop: "1.5rem",
                        border: "1px solid #ccc",
                        padding: "1rem",
                      }}
                    >
                      <Typography variant="h4" align="center">
                        Apply online in{" "}
                        <span
                          style={{ fontSize: "2rem" }}
                          className={classes.yellow}
                        >
                          3
                        </span>{" "}
                        easy steps
                      </Typography>
                      <ApplySteps disabledStates={stepsState} />
                    </Card>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Formik
                    initialValues={values}
                    isInitialValid={false}
                    onSubmit={(values, actions) => {
                      setTimeout(() => {
                        actions.setSubmitting(true);
                      }, 100);
                    }}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                        handleReset,
                        setFieldValue,
                      } = props;

                      return (
                        <Form>
                          <Typography className={classes.smallHeading}>
                            {state?.model?.fullModelName ?? relatedModel?.title}
                          </Typography>
                          <Typography variant="h4" color="textPrimary">
                            <strong>
                              <CurrencyValue
                                value={financeDetails.purchasePrice}
                              />
                            </strong>{" "}
                            or{" "}
                            <strong>
                              <CurrencyValue
                                value={financeDetails.monthlyInstallment}
                              />
                              pm
                            </strong>
                          </Typography>
                          <div className={classes.headerLine}></div>
                          {!dealId && (
                            <Box style={{ marginTop: 24 }}>
                              <TextField
                                fullWidth
                                id="model"
                                variant="outlined"
                                select
                                required
                                label="Select a model"
                                value={values.model}
                                helperText={
                                  errors.model && touched.model
                                    ? errors.model
                                    : ""
                                }
                                error={errors.model && touched.model}
                                onBlur={handleBlur("make")}
                                onChange={(e, child) => {
                                  handleModelChange(e, child, setFieldValue);
                                  setVariant(e?.target?.value)
                                  console.log("values",values)

                               
                                }}
                              >
                                {variantList &&
                                  variantList.map((option) => (
                                    <MenuItem
                                      key={option.id}
                                      id={option.id}
                                      value={option}
                                    >
                                      {option.title} (From{" "}
                                      <CurrencyValue
                                        value={option.priceRange}
                                      />
                                      )
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Box>
                          )}
                          {colorData && colorData.length > 0 && (
                            <Box style={{ marginTop: 24 }}>
                              <Typography className={classes.smallHeading}>
                                Select Colour
                              </Typography>
                              <Box className={classes.colorsContainer}>
                                {colorData.map((color) => {
                                  return (
                                    <Box
                                      className={classes.stepPaddingColours}
                                      key={`color-${color.id}`}
                                    >
                                      <Button
                                        style={{
                                          backgroundColor: `${color.colour}`,
                                          height: "60px",
                                          width: "60px",
                                          minWidth: "60px",
                                          borderRadius: "50%",
                                        }}
                                        onClick={(v) => {
                                          setColorId(color.id);
                                        }}
                                        variant={"contained"}
                                      >
                                        {colorId === color.id && (
                                          <CheckIcon
                                            style={{
                                              color: invertColor(
                                                `${color.colour}`
                                              ),
                                            }}
                                          />
                                        )}
                                      </Button>
                                      <Typography
                                        className={classes.colourName}
                                      >
                                        {color.title}
                                      </Typography>
                                      <Typography
                                        className={classes.colourPrice}
                                      >
                                        <CurrencyValue value={color.price} />
                                      </Typography>
                                    </Box>
                                  );
                                })}
                              </Box>
                            </Box>
                          )}
                          <PaymentDetails
                            displayPrice={false}
                            details={financeDetails}
                          />
                          <Box
                            mb={3}
                            mt={3}
                            className={classes.stepOneButtons}
                            style={{ width: "initial", display: "flex" }}
                          >
                            
                        <Box mb={3} mt={3} className={classes.stepOneButtons} style={{ width: 'initial', display: 'flex' }}>
                                                        <Link style={{ margin: '0 10px 10px 0' }}>
                                                            <Button className={classes.activebtn} variant="contained"  color="primary" gutterBottom onClick={() => { handlePageChange("/apply-for-finance", { ...model}) }} disabled={values.model == ""}>
                                                                Pre-Qualify Now
                                                            </Button>
                                                        </Link>
                                                        <Link style={{ margin: '0 10px' }}>
                                                            <Button className={classes.activebtn}  variant="contained" color="primary"
                           gutterBottom onClick={() => { handlePageChange(`/contact-us`, { ...model }) }} disabled={!isVariantSelected}>
                                                                Request Call back
                                                            </Button>
                                                        </Link>
                                                    </Box>
                                                  
                          </Box>
                          <Box>
                            <Typography
                              style={{ marginTop: ".5rem" }}
                              variant="caption"
                              color="textSecondary"
                            >
                              {financeDetails.termsAndConditions}
                            </Typography>
                          </Box>
                        </Form>
                      );
                    }}
                  </Formik>
                </Grid>
              </Grid>

              <Grid container className={classes.sectionPadding}>
                <Grid item xs={12}>
                  <ImageContainer
                    aspectRatio={16 / 6}
                    src={getContentImage("main")}
                    alt="MainImage"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={4}
                style={{ marginTop: "3rem", marginBottom: "3rem" }}
              >
                <Grid
                  item
                  container
                  alignContent="center"
                  justify="center"
                  xs={12}
                >
                  <Typography
                    className={classes.heading}
                    align="center"
                    id="discover"
                    gutterBottom
                    variant="h4"
                    color="textPrimary"
                    fontWeight="500"
                  >
                    {modelInfo.introductionTitle}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.paragraphPadding}>
                  <Typography>{modelInfo.introductionDesc}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <ImageContainer
                    height="100%"
                    src={getContentImage("small")}
                    alt="MainImage"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  container
                  alignContent="center"
                  justify="center"
                >
                  <Typography
                    className={classes.heading}
                    align="center"
                    gutterBottom
                    variant="h4"
                    color="textPrimary"
                    fontWeight="500"
                  >
                    {modelInfo.contentTitle1}
                  </Typography>
                  <Typography className={classes.paragraphPadding}>
                    {modelInfo.contentDesc1}
                  </Typography>
                  <div
                    className={classes.largeButton}
                    style={{ width: "initial" }}
                  >
                    <Box mb={3} mt={3}>
                      <Button
                        variant="contained"
                        gutterBottom
                        onClick={() => {
                          window.open(
                            modelInfo?.brochures[0]?.document?.filePath,
                            "_blank"
                          );
                        }}
                        color="primary"
                      >
                        Download Brochure
                      </Button>
                    </Box>
                    <Link href="/apply-for-finance" style={{ width: "100%" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ width: "100%" }}
                      >
                        Apply for Finance
                      </Button>
                    </Link>
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box mb={3}>
                    <ImageContainer
                      src={getContentImage("medium1")}
                      alt="MainImage"
                    />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Typography
                      className={classes.heading}
                      align="center"
                      gutterBottom
                      variant="h4"
                      color="textPrimary"
                      fontWeight="500"
                    >
                      {modelInfo.contentTitle2}
                    </Typography>
                  </Box>
                  <Typography className={classes.paragraphPadding}>
                    {modelInfo.contentDesc2}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box mb={3}>
                    <ImageContainer
                      src={getContentImage("medium2")}
                      alt="MainImage"
                    />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Typography
                      className={classes.heading}
                      align="center"
                      gutterBottom
                      variant="h4"
                      color="textPrimary"
                      fontWeight="500"
                    >
                      {modelInfo.contentTitle3}
                    </Typography>
                  </Box>
                  <Typography className={classes.paragraphPadding}>
                    {modelInfo.contentDesc3}
                  </Typography>
                </Grid>
              </Grid>
              {false && (
                <div>
                  <Grid
                    container
                    alignContent="center"
                    justify="center"
                    className={classes.sectionPadding}
                  >
                    <Grid
                      item
                      xs={8}
                      className={classes.yellowBorder}
                      md={4}
                    ></Grid>
                  </Grid>

                  <Grid container className={classes.sectionPadding}>
                    <Grid item xs={12}>
                      <Box mb={3}>
                        <Typography
                          className={classes.secondaryHeading}
                          style={{ paddingBottom: 0 }}
                          id="specifications"
                          align="center"
                          variant="h5"
                          color="textPrimary"
                        >
                          Specifications
                        </Typography>
                        <Typography
                          align="center"
                          gutterBottom
                          variant="subtitle2"
                          color="textPrimary"
                        >
                          Click/Tap on sections below to view details
                        </Typography>
                      </Box>
                    </Grid>
                    <SpecificationsComponent />
                  </Grid>
                </div>
              )}
              {!colorsLoading && colors.length > 0 && (
                <div>
                  <Grid
                    container
                    alignContent="center"
                    justify="center"
                    className={classes.sectionPadding}
                  >
                    <Grid
                      item
                      xs={8}
                      className={classes.yellowBorder}
                      md={4}
                    ></Grid>
                  </Grid>

                  <Grid container className={classes.sectionPadding}>
                    <Grid item xs={12}>
                      <Typography
                        className={classes.secondaryHeading}
                        align="center"
                        gutterBottom
                        variant="h5"
                        color="textPrimary"
                      >
                        Available Colors
                      </Typography>
                      <CarouselSingle
                        naturalSlideWidth={100}
                        naturalSlideHeight={60}
                        totalSlides={colors.length}
                        visibleSlides={3}
                        isPlaying={true}
                        infinite
                        innerClass={classes.slideMargin}
                        slides={colors}
                        onSlideClick={(index) => setActiveColor(index)}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}

              {!loading && modelInfo?.galleryImages?.length > 0 && (
                <div>
                  <Grid
                    container
                    alignContent="center"
                    justify="center"
                    className={classes.sectionPadding}
                  >
                    <Grid
                      item
                      xs={8}
                      className={classes.yellowBorder}
                      md={4}
                    ></Grid>
                  </Grid>

                  <Grid container className={classes.sectionPadding}>
                    <Grid item xs={12}>
                      <Typography
                        className={classes.secondaryHeading}
                        align="center"
                        id="images"
                        gutterBottom
                        variant="h5"
                        color="textPrimary"
                      >
                        Gallery
                      </Typography>

                      <CarouselSingle
                        naturalSlideWidth={100}
                        naturalSlideHeight={50}
                        totalSlides={modelInfo.galleryImages.length}
                        isPlaying={true}
                        infinite
                        slides={getGalleryImages()}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          )}
        </Container>
      </main>
    </React.Fragment>
  );
};

export default CarProfileContainer;
