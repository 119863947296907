import React, { createContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

export const FinanceContext = createContext();

export const FinanceProvider = ({ children }) => {
  const getItem = (itm) => {
    let data = localStorage.getItem(itm);

    if (data && data != "undefined") return JSON.parse(data);
    else return {};
  };

  const setItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const [globalFinance, setGlobalFinance] = useState(getItem("globalFinance"));
  const [globalVehicle, setGlobalVehicle] = useState(getItem("globalVehicle"));
  const [globalColor, setGlobalColor] = useState(getItem("globalColor"));
  const [globalModel, setGlobalModel] = useState(getItem("globalModel"));
  const [globalVariantId, setGlobalVariantId] = useState(0);
  const [globalLead, setGlobalLead] = useState(getItem("globalLead"));
  const [globalDealerContactDetails, setGlobalDealerContactDetails] = useState(
    getItem("globalDealerContactDetails")
  );
  const [globalDealerVehicles, setGlobalDealerVehicles] = useState(
    getItem("globalDealerVehicles")
  );

  useEffect(() => {
    setItem("globalFinance", globalFinance);
  }, [globalFinance]);

  useEffect(() => {
    setItem("globalFinance", globalVehicle);
  }, [globalVehicle]);

  useEffect(() => {
    setItem("globalLead", globalLead);
  }, [globalLead]);

  useEffect(() => {
    setItem("globalColor", globalColor);
  }, [globalColor]);

  useEffect(() => {
    setItem("globalDealerContactDetails", globalDealerContactDetails);
  }, [globalDealerContactDetails]);

  useEffect(() => {
    setItem("globalDealerVehicles", globalDealerVehicles);
  }, [globalDealerVehicles]);

  return (
    <FinanceContext.Provider
      value={{
        globalFinance,
        setGlobalFinance,
        globalColor,
        setGlobalColor,
        globalVariantId,
        setGlobalVariantId,
        globalVehicle,
        setGlobalVehicle,
        globalLead,
        setGlobalLead,
        globalModel,
        setGlobalModel,
        globalDealerContactDetails,
        setGlobalDealerContactDetails,
        globalDealerVehicles,
        setGlobalDealerVehicles,
      }}
    >
      {children}
    </FinanceContext.Provider>
  );
};
